.receiptPage {
  display: flex;
  flex-direction: column;
  gap: 2em;
  align-items: center;
  text-align: center;
  max-width: 30em;
}

.address {
  white-space: pre;
}

.coin {
  width: 8em;
  height: 8em;
}
