.declineModalContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  gap: 2em;
}

.buttonContainer {
  display: flex;
  gap: 1em;
}
