@use "../../CommonCSS/HeaderSize.module" as HeaderSize;

.content {
  width: 100%;
  height: HeaderSize.$non-header-height;
  position: relative;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  // adjust size of everything, since everything is in terms of em
  font-size: 0.75em;

  @media (orientation: portrait) {
    @media (min-width: 400px) {
      font-size: 1em;
    }

    @media (min-width: 600px) {
      font-size: 1.25em;
    }
  }

  @media (orientation: landscape) {
    @media (min-height: 480px) {
      font-size: 1em;
    }

    @media (min-height: 650px) {
      font-size: 1.5em;
    }

    @media (min-height: 880px) {
      font-size: 2em;
    }
  }
}
