@use "../../CommonCSS/Text.module" as Text;
@use "../../CommonCSS/Colors.module" as Colors;

.dropdown {
  border: 0;
  border-radius: 0.5em 0.5em 0 0;
  border-bottom: 0.125em solid black;
  font-size: 1em;
  font-family: Text.$font-stack;
  font-style: normal;
  font-weight: normal;
  width: 50%;

  &.unfilled {
    color: #757575;
  }
}
