@use "../../CommonCSS/HeaderSize.module" as HeaderSize;

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-image: linear-gradient(
    to right,
    rgb(34, 44, 63),
    rgb(41, 68, 111)
  );
  width: 100%;

  height: HeaderSize.$header-height;
}

.rightSideContainer {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.logo {
  height: 75%;
  margin-left: 0.5em;
  margin-right: 0.5em;
}
