.modalContainer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.modalBackdrop {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 0;
}

.modal {
  background-color: white;
  border-radius: 1em;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.48);
  z-index: 1;
  max-width: 60%;
}

.modalContent {
  padding: 0 2em 2em 2em;
}

.xButtonRow {
  display: flex;
  justify-content: flex-end;
  height: 2em;
}

.xButton {
  width: 1.5em;
  height: 1.5em;
  background: none;
  border: none;
  cursor: pointer;
  margin: 0.25em;
}
