@use "./Text.module" as Text;

:global {
  body {
    margin: 0;
    width: 100%;
    min-height: 100vh;
    font-family: Text.$font-stack;
  }
}
