@use "../../CommonCSS/Colors.module.scss" as colors;

.button {
  min-width: 5em;
  text-transform: uppercase;
  text-align: center;
  border: 0;
  border-radius: 0.25em;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.48);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;

  &.red {
    background: colors.$red;
  }

  &.orange {
    background: colors.$orange;
  }

  &.disabled {
    opacity: 0.5;
    cursor: auto;
  }

  .text {
    margin: 0.1em 0.5em;
  }
}
