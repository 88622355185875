@use "../../CommonCSS/Colors.module.scss" as Colors;
@use "../../CommonCSS/Text.module" as Text;

.text {
  font-family: Text.$font-stack;
  line-height: 1.2em;

  // color
  &.colorRed {
    color: Colors.$red;
  }

  &.colorBlue {
    color: Colors.$darkBlue;
  }

  &.colorOrange {
    color: Colors.$orange;
  }

  &.colorBlack {
    color: Colors.$black;
  }

  &.colorWhite {
    color: Colors.$white;
  }

  // weight
  &.weightNormal {
    font-weight: 400;
  }

  &.weightBold {
    font-weight: 600;
  }

  &.weightBlack {
    font-weight: 900;
  }

  // size
  &.sizeSmall {
    font-size: Text.$small;
  }

  &.sizeMedium {
    font-size: Text.$medium;
  }

  &.sizeLarge {
    font-size: Text.$large;
  }
}
