.formPage {
  display: flex;
  justify-content: center;
  width: 100%;
  form {
    width: 80%;
    max-width: calc(100vw - 3em);
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  text-align: center;
  padding: 0;
  margin: 0;
}

.descriptionContainer {
  margin-bottom: 0.5em;
  display: flex;
  flex-direction: column;
}

.buttonRow {
  display: flex;
  gap: 1em;
  justify-content: space-between;
}

.inputContainer {
  min-width: 16em;
  width: 80%;
  max-width: 30em;
}

.inputRow {
  display: flex;
  margin-bottom: 0.5em;
  gap: 0.5em;
}

.inputItem {
  width: 50%;

  &.addressInput {
    width: 100%;
  }
  &.apartmentInput {
    width: 100%;
  }
}

.checkboxRow {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  gap: 0.25em;

  .checkboxLabel {
    line-height: 1em;
  }
}
