@use "../../CommonCSS/HeaderSize.module" as HeaderSize;

.background {
  position: absolute;
  width: 100%;
  overflow: hidden;
  height: HeaderSize.$non-header-height;

  .translucent {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 2;
    position: relative;
  }
}

.portraitBackgroundImages {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  overflow: hidden;
}

.portraitBackgroundContainer {
  height: 86vh;
  display: flex;
  justify-content: space-between;
  margin-right: 0;
}

.portraitCol {
  bottom: 20vh;
  display: grid;
  align-items: end;
}

.portraitRightGroup {
  max-width: 51vw;
  min-height: 30vh;
  position: relative;
  object-fit: scale-down;
  overflow: hidden;
  top: 22vh;
  animation: fadeIn 1.5s;
}

.portraitLeftGroup {
  max-width: 49vw;
  min-height: 30vh;
  position: relative;
  object-fit: scale-down;
  overflow: hidden;
  left: 0;
  top: 20vh;
  animation: fadeIn 1.5s;
}

.landscapeBackgroundImages {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  overflow: hidden;
}

.backgroundRow {
  height: 30vh;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.bat {
  max-width: 20vw;
}
.ape {
  max-width: 23vw;
}
.max {
  max-width: 13vw;
}
.cat {
  max-width: 23vw;
}
.felix {
  max-width: 20vw;
}
.alien {
  max-width: 32vw;
}

.imageContainer {
  animation: fadeIn 1.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
