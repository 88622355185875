.container {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  text-align: center;
}

.addressButtonRow {
  display: flex;
  gap: 1em;
  justify-content: center;
  flex-direction: row;

  @media (orientation: portrait) {
    flex-direction: column;
  }
}

.actionButtonRow {
  display: flex;
  gap: 1em;
  justify-content: center;
}
