@use "../../CommonCSS/Colors.module" as Colors;

.container {
  border: 0.2em solid Colors.$black;
  border-radius: 1em;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  font-size: 1em;
  opacity: 0.5;

  &.selected {
    border-color: Colors.$blue;
    opacity: 1;
  }
}

.label {
  position: absolute;
  top: -0.5em;
  width: 100%;
  display: flex;
  justify-content: center;

  .labelText {
    padding: 0 1em;
    background-color: white;
  }
}

.address {
  white-space: pre;
  padding: 2em 1em;
}
